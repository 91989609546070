<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>{{ isEditing ? "Edit" : "Add a New" }} Monthly Template</h1>
        </v-col>
      </v-row>
      <v-divider class="mb-6"></v-divider>
      <v-form @submit.prevent="saveForm" method="post" id="template-form">
        <v-row no-gutters>
          <v-col cols="4" class="mr-6">
            <v-select
              label="Month*"
              :items="formFields.months"
              item-value="value"
              item-text="text"
              v-model="fields.month"
              outlined
              :error="errors.hasOwnProperty('month')"
              :error-messages="errors['month']"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              label="Year*"
              :items="formYears"
              item-value="value"
              item-text="text"
              item-disabled="disabled"
              v-model="fields.year"
              outlined
              :error="errors.hasOwnProperty('year')"
              :error-messages="errors['year']"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="mr-6">
            <v-select
              label="Category"
              :items="formFields.categories"
              v-model="fields.category"
              outlined
              :error="errors.hasOwnProperty('category')"
              :error-messages="errors['category']"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Theme*"
              v-model="fields.theme"
              outlined
              :error="errors.hasOwnProperty('theme')"
              :error-messages="errors['theme']"
            />
          </v-col>
        </v-row>
        <v-text-field
          label="Intention*"
          outlined
          v-model="fields.intention"
          :error="errors.hasOwnProperty('intention')"
          :error-messages="errors['intention']"
        />
        <v-textarea
          label="Summary*"
          outlined
          v-model="fields.monthly_summary"
          :error="errors.hasOwnProperty('monthly_summary')"
          :error-messages="errors['monthly_summary']"
        ></v-textarea>
        <v-textarea
          label="Theme Text"
          outlined
          v-model="fields.paragraph_text"
          :error="errors.hasOwnProperty('paragraph_text')"
          :error-messages="errors['paragraph_text']"
        ></v-textarea>
        <FileUpload
          ref="videoUpload"
          label='<b>Video Upload</b><br />Drag & Drop your video or <span class="filepond--label-action"> Browse </span>'
        />
        <v-textarea
          label="Questions"
          outlined
          v-model="fields.question_text"
        ></v-textarea>
        <v-row no-gutters>
          <v-col cols="4" class="pr-6">
            <FileUpload
              ref="audioUpload1"
              label='<b>1 Min Audio File</b><br />Drag & Drop your audio file or <span class="filepond--label-action"> Browse </span>'
              type="audio"
            />
          </v-col>
          <v-col cols="4" class="pr-6">
            <FileUpload
              ref="audioUpload2"
              label='<b>3 Min Audio File</b><br />Drag & Drop your audio file or <span class="filepond--label-action"> Browse </span>'
              type="audio"
            />
          </v-col>
          <v-col cols="4">
            <FileUpload
              ref="audioUpload3"
              label='<b>5 Min Audio File</b><br />Drag & Drop your audio file or <span class="filepond--label-action"> Browse </span>'
              type="audio"
            />
          </v-col>
        </v-row>

        <v-text-field
          label="Spotify Playlist"
          outlined
          v-model="fields.spotify_playlist"
        ></v-text-field>

        <v-text-field
          label="Zoom Meeting Link"
          outlined
          v-model="fields.zoom_link"
        ></v-text-field>
        <v-text-field
          label="Zoom Meeting Password"
          outlined
          v-model="fields.zoom_password"
        ></v-text-field>
        <v-text-field
          label="Zoom Meeting Time"
          type="time"
          prepend-icon="mdi-clock-outline"
          outlined
          v-model="fields.zoom_at_time"
        ></v-text-field>
        <v-menu
          v-model="zoomDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fields.zoom_at_date"
              label="Zoom Meeting Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fields.zoom_at_date"
            @input="zoomDatePicker = false"
            :min="new Date().toISOString().substr(0, 10)"
          ></v-date-picker>
        </v-menu>
        <v-text-field
          label="Zoom Recording Link"
          outlined
          v-model="fields.zoom_recording_link"
        ></v-text-field>
        <v-text-field
          label="Second Zoom Meeting Link"
          outlined
          v-model="fields.second_zoom_link"
        ></v-text-field>
        <v-text-field
          label="Second Zoom Meeting Password"
          outlined
          v-model="fields.second_zoom_password"
        ></v-text-field>
        <v-text-field
          label="Second Zoom Meeting Time"
          type="time"
          prepend-icon="mdi-clock-outline"
          outlined
          v-model="fields.second_zoom_at_time"
        ></v-text-field>
        <v-menu
          v-model="secondZoomDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fields.second_zoom_at_date"
              label="Second Zoom Meeting Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fields.second_zoom_at_date"
            @input="secondZoomDatePicker = false"
            :min="new Date().toISOString().substr(0, 10)"
          ></v-date-picker>
        </v-menu>
        <v-text-field
          label="Second Zoom Recording Link"
          outlined
          v-model="fields.second_zoom_recording_link"
        ></v-text-field>
        <v-btn type="submit" depressed color="accent" :loading="loading"
          >Save</v-btn
        >
      </v-form>
    </v-container>
  </div>
</template>

<script>
import FileUpload from "../components/FileUpload";

export default {
  components: {
    FileUpload,
  },

  props: {
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      searchTerm: "",
      loading: false,
      errors: {},
      breadcrumbs: [
        {
          text: "Monthly Templates",
          disabled: false,
          exact: true,
          to: {
            name: "module-flightmode-monthlytemplates",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Create",
          disabled: true,
        },
      ],
      formFields: {
        months: [
          {
            text: "January",
            value: 1,
          },
          {
            text: "February",
            value: 2,
          },
          {
            text: "March",
            value: 3,
          },
          {
            text: "April",
            value: 4,
          },
          {
            text: "May",
            value: 5,
          },
          {
            text: "June",
            value: 6,
          },
          {
            text: "July",
            value: 7,
          },
          {
            text: "August",
            value: 8,
          },
          {
            text: "September",
            value: 9,
          },
          {
            text: "October",
            value: 10,
          },
          {
            text: "November",
            value: 11,
          },
          {
            text: "December",
            value: 12,
          },
        ],
        categories: ["Earth", "Fire", "Water", "Wind"],
      },
      fields: {
        month: null,
        year: null,
        category: null,
        theme: "",
        monthly_summary: "",
        intention: "",
        paragraph_text: "",
        question_text: "",
        zoom_link: "",
        zoom_password: "",
        zoom_at_time: "",
        zoom_at_date: "",
        second_zoom_link: "",
        second_zoom_password: "",
        second_zoom_at_time: "",
        second_zoom_at_date: "",
        zoom_recording_link: "",
        second_zoom_recording_link: "",
        spotify_playlist: "",
      },
      monthlyTemplate: {},
      zoomDatePicker: false,
      secondZoomDatePicker: false,
    };
  },

  created() {
    if (this.isEditing) {
      const template =
        this.$store.state.flightmode.monthlyTemplates["template"];
      this.fields.month = parseInt(template.month);
      this.fields.year = parseInt(template.year);
      this.fields.monthly_summary = template.monthly_summary;
      this.fields.intention = template.intention;
      this.fields.category = template.category;
      this.fields.theme = template.theme;
      this.fields.paragraph_text = template.paragraph_text;
      this.fields.question_text = template.question_text;
      this.fields.zoom_link = template.zoom_link;
      this.fields.zoom_password = template.zoom_password;
      this.fields.zoom_at_time = template.formatted_dates.zoom_at_time;
      this.fields.zoom_at_date = template.formatted_dates.zoom_at_date;
      this.fields.zoom_recording_link = template.zoom_recording_link;
      this.fields.second_zoom_link = template.second_zoom_link;
      this.fields.second_zoom_password = template.second_zoom_password;
      this.fields.second_zoom_recording_link =
        template.second_zoom_recording_link;
      this.fields.spotify_playlist = template.spotify_playlist;
      this.fields.second_zoom_at_time =
        template.formatted_dates.second_zoom_at_time;
      this.fields.second_zoom_at_date =
        template.formatted_dates.second_zoom_at_date;
      this.monthly_template = template;
    }
  },

  mounted() {
    if (this.isEditing) {
      const template =
        this.$store.state.flightmode.monthlyTemplates["template"];

      if (template.one_min_audio) {
        this.$refs.audioUpload1.setFile(template.one_min_audio);
      }

      if (template.three_min_audio) {
        this.$refs.audioUpload2.setFile(template.three_min_audio);
      }

      if (template.five_min_audio) {
        this.$refs.audioUpload3.setFile(template.five_min_audio);
      }

      if (template.video_link) {
        this.$refs.videoUpload.setFile(template.video_link + ".mp4");
      }
    }
  },

  computed: {
    formYears() {
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth() + 1;
      const chosenMonth = this.fields.month;

      const years = [
        {
          text: currentYear,
          value: currentYear,
          disabled:
            chosenMonth === null
              ? false
              : chosenMonth < currentMonth
              ? true
              : false,
        },
      ];

      for (let i = 0; i < 4; i++) {
        currentYear++;
        years.push({
          text: currentYear,
          value: currentYear,
          disabled: false,
        });
      }

      return years;
    },
  },

  methods: {
    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        isEditing: this.isEditing,
      };

      if (this.isEditing) {
        payload.templateId = this.monthly_template.id;
      }

      let formData = new FormData();
      formData.append("month", this.fields.month);
      formData.append("year", this.fields.year);
      formData.append("monthly_summary", this.fields.monthly_summary);
      formData.append("intention", this.fields.intention);
      formData.append("category", this.fields.category);
      formData.append("theme", this.fields.theme);
      formData.append("paragraph_text", this.fields.paragraph_text ?? "");
      formData.append("question_text", this.fields.question_text ?? "");
      formData.append("zoom_link", this.fields.zoom_link ?? "");
      formData.append("zoom_password", this.fields.zoom_password ?? "");
      formData.append(
        "zoom_recording_link",
        this.fields.zoom_recording_link ?? ""
      );

      formData.append(
        "second_zoom_recording_link",
        this.fields.second_zoom_recording_link ?? ""
      );

      formData.append("spotify_playlist", this.fields.spotify_playlist ?? "");

      if (this.fields.zoom_at_time && this.fields.zoom_at_date) {
        formData.append("zoom_at_time", this.fields.zoom_at_time ?? "");
        formData.append("zoom_at_date", this.fields.zoom_at_date ?? "");
      }

      formData.append("second_zoom_link", this.fields.second_zoom_link ?? "");
      formData.append(
        "second_zoom_password",
        this.fields.second_zoom_password ?? ""
      );

      if (this.fields.second_zoom_at_time && this.fields.second_zoom_at_date) {
        formData.append(
          "second_zoom_at_time",
          this.fields.second_zoom_at_time ?? ""
        );
        formData.append(
          "second_zoom_at_date",
          this.fields.second_zoom_at_date ?? ""
        );
      }

      if (this.$refs.audioUpload1.hasFile()) {
        formData.append("one_min_audio", this.$refs.audioUpload1.getFile());
      } else if (this.$refs.audioUpload1.isDeleted()) {
        formData.append("one_min_audio_delete", true);
      }

      if (this.$refs.audioUpload2.hasFile()) {
        formData.append("three_min_audio", this.$refs.audioUpload2.getFile());
      } else if (this.$refs.audioUpload2.isDeleted()) {
        formData.append("three_min_audio_delete", true);
      }

      if (this.$refs.audioUpload3.hasFile()) {
        formData.append("five_min_audio", this.$refs.audioUpload3.getFile());
      } else if (this.$refs.audioUpload3.isDeleted()) {
        formData.append("five_min_audio_delete", true);
      }

      if (this.$refs.videoUpload.hasFile()) {
        formData.append("video_link", this.$refs.videoUpload.getFile());
      } else if (this.$refs.videoUpload.isDeleted()) {
        formData.append("video_link_delete", true);
      }

      this.$store
        .dispatch("flightmode/monthlyTemplates/saveTemplate", {
          formData,
          ...payload,
        })
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "module-flightmode-monthlytemplates" });
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
